export const isoCountries = [
    {'name':'Afghanistan','iso2_code':'AF','iso3_code':'AFG','eu':false,'europe':false},
    {'name':'Aland Islands','iso2_code':'AX','iso3_code':'ALA','eu':false,'europe':false},
    {'name':'Albania','iso2_code':'AL','iso3_code':'ALB','eu':false,'europe':true},
    {'name':'Algeria','iso2_code':'DZ','iso3_code':'DZA','eu':false,'europe':false},
    {'name':'American Samoa','iso2_code':'AS','iso3_code':'ASM','eu':false,'europe':false},
    {'name':'Andorra','iso2_code':'AD','iso3_code':'AND','eu':false,'europe':true},
    {'name':'Angola','iso2_code':'AO','iso3_code':'AGO','eu':false,'europe':false},
    {'name':'Anguilla','iso2_code':'AI','iso3_code':'AIA','eu':false,'europe':false},
    {'name':'Antarctica','iso2_code':'AQ','iso3_code':'ATA','eu':false,'europe':false},
    {'name':'Antigua and Barbuda','iso2_code':'AG','iso3_code':'ATG','eu':false,'europe':false},
    {'name':'Argentina','iso2_code':'AR','iso3_code':'ARG','eu':false,'europe':false},
    {'name':'Armenia','iso2_code':'AM','iso3_code':'ARM','eu':false,'europe':true},
    {'name':'Aruba','iso2_code':'AW','iso3_code':'ABW','eu':false,'europe':false},
    {'name':'Australia','iso2_code':'AU','iso3_code':'AUS','eu':false,'europe':false},
    {'name':'Austria','iso2_code':'AT','iso3_code':'AUT','eu':true,'europe':true},
    {'name':'Azerbaijan','iso2_code':'AZ','iso3_code':'AZE','eu':false,'europe':true},
    {'name':'Bahamas','iso2_code':'BS','iso3_code':'BHS','eu':false,'europe':false},
    {'name':'Bahrain','iso2_code':'BH','iso3_code':'BHR','eu':false,'europe':false},
    {'name':'Bangladesh','iso2_code':'BD','iso3_code':'BGD','eu':false,'europe':false},
    {'name':'Barbados','iso2_code':'BB','iso3_code':'BRB','eu':false,'europe':false},
    {'name':'Belarus','iso2_code':'BY','iso3_code':'BLR','eu':false,'europe':true},
    {'name':'Belgium','iso2_code':'BE','iso3_code':'BEL','eu':true,'europe':true},
    {'name':'Belize','iso2_code':'BZ','iso3_code':'BLZ','eu':false,'europe':false},
    {'name':'Benin','iso2_code':'BJ','iso3_code':'BEN','eu':false,'europe':false},
    {'name':'Bermuda','iso2_code':'BM','iso3_code':'BMU','eu':false,'europe':false},
    {'name':'Bhutan','iso2_code':'BT','iso3_code':'BTN','eu':false,'europe':false},
    {'name':'Bolivia','iso2_code':'BO','iso3_code':'BOL','eu':false,'europe':false},
    {'name':'Bosnia and Herzegovina','iso2_code':'BA','iso3_code':'BIH','eu':false,'europe':true},
    {'name':'Botswana','iso2_code':'BW','iso3_code':'BWA','eu':false,'europe':false},
    {'name':'Bouvet Island','iso2_code':'BV','iso3_code':'BVT','eu':false,'europe':false},
    {'name':'Brazil','iso2_code':'BR','iso3_code':'BRA','eu':false,'europe':false},
    {'name':'British Virgin Islands','iso2_code':'VG','iso3_code':'VGB','eu':false,'europe':false},
    {'name':'British Indian Ocean Territory','iso2_code':'IO','iso3_code':'IOT','eu':false,'europe':false},
    {'name':'Brunei Darussalam','iso2_code':'BN','iso3_code':'BRN','eu':false,'europe':false},
    {'name':'Bulgaria','iso2_code':'BG','iso3_code':'BGR','eu':true,'europe':true},
    {'name':'Burkina Faso','iso2_code':'BF','iso3_code':'BFA','eu':false,'europe':false},
    {'name':'Burundi','iso2_code':'BI','iso3_code':'BDI','eu':false,'europe':false},
    {'name':'Cambodia','iso2_code':'KH','iso3_code':'KHM','eu':false,'europe':false},
    {'name':'Cameroon','iso2_code':'CM','iso3_code':'CMR','eu':false,'europe':false},
    {'name':'Canada','iso2_code':'CA','iso3_code':'CAN','eu':false,'europe':false},
    {'name':'Cape Verde','iso2_code':'CV','iso3_code':'CPV','eu':false,'europe':false},
    {'name':'Cayman Islands','iso2_code':'KY','iso3_code':'CYM','eu':false,'europe':false},
    {'name':'Central African Republic','iso2_code':'CF','iso3_code':'CAF','eu':false,'europe':false},
    {'name':'Chad','iso2_code':'TD','iso3_code':'TCD','eu':false,'europe':false},
    {'name':'Chile','iso2_code':'CL','iso3_code':'CHL','eu':false,'europe':false},
    {'name':'China','iso2_code':'CN','iso3_code':'CHN','eu':false,'europe':false},
    {'name':'Hong Kong, SAR China','iso2_code':'HK','iso3_code':'HKG','eu':false,'europe':false},
    {'name':'Macao, SAR China','iso2_code':'MO','iso3_code':'MAC','eu':false,'europe':false},
    {'name':'Christmas Island','iso2_code':'CX','iso3_code':'CXR','eu':false,'europe':false},
    {'name':'Cocos (Keeling) Islands','iso2_code':'CC','iso3_code':'CCK','eu':false,'europe':false},
    {'name':'Colombia','iso2_code':'CO','iso3_code':'COL','eu':false,'europe':false},
    {'name':'Comoros','iso2_code':'KM','iso3_code':'COM','eu':false,'europe':false},
    {'name':'Congo (Brazzaville)','iso2_code':'CG','iso3_code':'COG','eu':false,'europe':false},
    {'name':'Congo, (Kinshasa)','iso2_code':'CD','iso3_code':'COD','eu':false,'europe':false},
    {'name':'Cook Islands','iso2_code':'CK','iso3_code':'COK','eu':false,'europe':false},
    {'name':'Costa Rica','iso2_code':'CR','iso3_code':'CRI','eu':false,'europe':false},
    {'name':'Côte d\'Ivoire','iso2_code':'CI','iso3_code':'CIV','eu':false,'europe':false},
    {'name':'Croatia','iso2_code':'HR','iso3_code':'HRV','eu':true,'europe':true},
    {'name':'Cuba','iso2_code':'CU','iso3_code':'CUB','eu':false,'europe':false},
    {'name':'Cyprus','iso2_code':'CY','iso3_code':'CYP','eu':true,'europe':true},
    {'name':'Czech Republic','iso2_code':'CZ','iso3_code':'CZE','eu':true,'europe':true},
    {'name':'Denmark','iso2_code':'DK','iso3_code':'DNK','eu':true,'europe':true},
    {'name':'Djibouti','iso2_code':'DJ','iso3_code':'DJI','eu':false,'europe':false},
    {'name':'Dominica','iso2_code':'DM','iso3_code':'DMA','eu':false,'europe':false},
    {'name':'Dominican Republic','iso2_code':'DO','iso3_code':'DOM','eu':false,'europe':false},
    {'name':'Ecuador','iso2_code':'EC','iso3_code':'ECU','eu':false,'europe':false},
    {'name':'Egypt','iso2_code':'EG','iso3_code':'EGY','eu':false,'europe':false},
    {'name':'El Salvador','iso2_code':'SV','iso3_code':'SLV','eu':false,'europe':false},
    {'name':'Equatorial Guinea','iso2_code':'GQ','iso3_code':'GNQ','eu':false,'europe':false},
    {'name':'Eritrea','iso2_code':'ER','iso3_code':'ERI','eu':false,'europe':false},
    {'name':'Estonia','iso2_code':'EE','iso3_code':'EST','eu':true,'europe':true},
    {'name':'Ethiopia','iso2_code':'ET','iso3_code':'ETH','eu':false,'europe':false},
    {'name':'Falkland Islands (Malvinas)','iso2_code':'FK','iso3_code':'FLK','eu':false,'europe':false},
    {'name':'Faroe Islands','iso2_code':'FO','iso3_code':'FRO','eu':false,'europe':false},
    {'name':'Fiji','iso2_code':'FJ','iso3_code':'FJI','eu':false,'europe':false},
    {'name':'Finland','iso2_code':'FI','iso3_code':'FIN','eu':true,'europe':true},
    {'name':'France','iso2_code':'FR','iso3_code':'FRA','eu':true,'europe':true},
    {'name':'French Guiana','iso2_code':'GF','iso3_code':'GUF','eu':false,'europe':false},
    {'name':'French Polynesia','iso2_code':'PF','iso3_code':'PYF','eu':false,'europe':false},
    {'name':'French Southern Territories','iso2_code':'TF','iso3_code':'ATF','eu':false,'europe':false},
    {'name':'Gabon','iso2_code':'GA','iso3_code':'GAB','eu':false,'europe':false},
    {'name':'Gambia','iso2_code':'GM','iso3_code':'GMB','eu':false,'europe':false},
    {'name':'Georgia','iso2_code':'GE','iso3_code':'GEO','eu':false,'europe':true},
    {'name':'Germany','iso2_code':'DE','iso3_code':'DEU','eu':true,'europe':true},
    {'name':'Ghana','iso2_code':'GH','iso3_code':'GHA','eu':false,'europe':false},
    {'name':'Gibraltar','iso2_code':'GI','iso3_code':'GIB','eu':false,'europe':false},
    {'name':'Greece','iso2_code':'GR','iso3_code':'GRC','eu':true,'europe':true},
    {'name':'Greenland','iso2_code':'GL','iso3_code':'GRL','eu':false,'europe':false},
    {'name':'Grenada','iso2_code':'GD','iso3_code':'GRD','eu':false,'europe':false},
    {'name':'Guadeloupe','iso2_code':'GP','iso3_code':'GLP','eu':false,'europe':false},
    {'name':'Guam','iso2_code':'GU','iso3_code':'GUM','eu':false,'europe':false},
    {'name':'Guatemala','iso2_code':'GT','iso3_code':'GTM','eu':false,'europe':false},
    {'name':'Guernsey','iso2_code':'GG','iso3_code':'GGY','eu':false,'europe':false},
    {'name':'Guinea','iso2_code':'GN','iso3_code':'GIN','eu':false,'europe':false},
    {'name':'Guinea-Bissau','iso2_code':'GW','iso3_code':'GNB','eu':false,'europe':false},
    {'name':'Guyana','iso2_code':'GY','iso3_code':'GUY','eu':false,'europe':false},
    {'name':'Haiti','iso2_code':'HT','iso3_code':'HTI','eu':false,'europe':false},
    {'name':'Heard and Mcdonald Islands','iso2_code':'HM','iso3_code':'HMD','eu':false,'europe':false},
    {'name':'Holy See (Vatican City State)','iso2_code':'VA','iso3_code':'VAT','eu':false,'europe':true},
    {'name':'Honduras','iso2_code':'HN','iso3_code':'HND','eu':true,'europe':true},
    {'name':'Hungary','iso2_code':'HU','iso3_code':'HUN','eu':false,'europe':false},
    {'name':'Iceland','iso2_code':'IS','iso3_code':'ISL','eu':false,'europe':true},
    {'name':'India','iso2_code':'IN','iso3_code':'IND','eu':false,'europe':false},
    {'name':'Indonesia','iso2_code':'ID','iso3_code':'IDN','eu':false,'europe':false},
    {'name':'Iran, Islamic Republic of','iso2_code':'IR','iso3_code':'IRN','eu':false,'europe':false},
    {'name':'Iraq','iso2_code':'IQ','iso3_code':'IRQ','eu':false,'europe':false},
    {'name':'Ireland','iso2_code':'IE','iso3_code':'IRL','eu':true,'europe':true},
    {'name':'Isle of Man','iso2_code':'IM','iso3_code':'IMN','eu':false,'europe':false},
    {'name':'Israel','iso2_code':'IL','iso3_code':'ISR','eu':false,'europe':false},
    {'name':'Italy','iso2_code':'IT','iso3_code':'ITA','eu':true,'europe':true},
    {'name':'Jamaica','iso2_code':'JM','iso3_code':'JAM','eu':false,'europe':false},
    {'name':'Japan','iso2_code':'JP','iso3_code':'JPN','eu':false,'europe':false},
    {'name':'Jersey','iso2_code':'JE','iso3_code':'JEY','eu':false,'europe':false},
    {'name':'Jordan','iso2_code':'JO','iso3_code':'JOR','eu':false,'europe':false},
    {'name':'Kazakhstan','iso2_code':'KZ','iso3_code':'KAZ','eu':false,'europe':true},
    {'name':'Kenya','iso2_code':'KE','iso3_code':'KEN','eu':false,'europe':false},
    {'name':'Kiribati','iso2_code':'KI','iso3_code':'KIR','eu':false,'europe':false},
    {'name':'Korea (North)','iso2_code':'KP','iso3_code':'PRK','eu':false,'europe':false},
    {'name':'Korea (South)','iso2_code':'KR','iso3_code':'KOR','eu':false,'europe':false},
    {'name':'Kuwait','iso2_code':'KW','iso3_code':'KWT','eu':false,'europe':false},
    {'name':'Kyrgyzstan','iso2_code':'KG','iso3_code':'KGZ','eu':false,'europe':false},
    {'name':'Lao PDR','iso2_code':'LA','iso3_code':'LAO','eu':false,'europe':false},
    {'name':'Latvia','iso2_code':'LV','iso3_code':'LVA','eu':true,'europe':true},
    {'name':'Lebanon','iso2_code':'LB','iso3_code':'LBN','eu':false,'europe':false},
    {'name':'Lesotho','iso2_code':'LS','iso3_code':'LSO','eu':false,'europe':false},
    {'name':'Liberia','iso2_code':'LR','iso3_code':'LBR','eu':false,'europe':false},
    {'name':'Libya','iso2_code':'LY','iso3_code':'LBY','eu':false,'europe':false},
    {'name':'Liechtenstein','iso2_code':'LI','iso3_code':'LIE','eu':false,'europe':true},
    {'name':'Lithuania','iso2_code':'LT','iso3_code':'LTU','eu':true,'europe':true},
    {'name':'Luxembourg','iso2_code':'LU','iso3_code':'LUX','eu':true,'europe':true},
    {'name':'Macedonia, Republic of','iso2_code':'MK','iso3_code':'MKD','eu':false,'europe':true},
    {'name':'Madagascar','iso2_code':'MG','iso3_code':'MDG','eu':false,'europe':false},
    {'name':'Malawi','iso2_code':'MW','iso3_code':'MWI','eu':false,'europe':false},
    {'name':'Malaysia','iso2_code':'MY','iso3_code':'MYS','eu':false,'europe':false},
    {'name':'Maldives','iso2_code':'MV','iso3_code':'MDV','eu':false,'europe':false},
    {'name':'Mali','iso2_code':'ML','iso3_code':'MLI','eu':false,'europe':false},
    {'name':'Malta','iso2_code':'MT','iso3_code':'MLT','eu':true,'europe':true},
    {'name':'Marshall Islands','iso2_code':'MH','iso3_code':'MHL','eu':false,'europe':false},
    {'name':'Martinique','iso2_code':'MQ','iso3_code':'MTQ','eu':false,'europe':false},
    {'name':'Mauritania','iso2_code':'MR','iso3_code':'MRT','eu':false,'europe':false},
    {'name':'Mauritius','iso2_code':'MU','iso3_code':'MUS','eu':false,'europe':false},
    {'name':'Mayotte','iso2_code':'YT','iso3_code':'MYT','eu':false,'europe':false},
    {'name':'Mexico','iso2_code':'MX','iso3_code':'MEX','eu':false,'europe':false},
    {'name':'Micronesia','iso2_code':'FM','iso3_code':'FSM','eu':false,'europe':false},
    {'name':'Moldova','iso2_code':'MD','iso3_code':'MDA','eu':false,'europe':true},
    {'name':'Monaco','iso2_code':'MC','iso3_code':'MCO','eu':false,'europe':true},
    {'name':'Mongolia','iso2_code':'MN','iso3_code':'MNG','eu':false,'europe':false},
    {'name':'Montenegro','iso2_code':'ME','iso3_code':'MNE','eu':false,'europe':true},
    {'name':'Montserrat','iso2_code':'MS','iso3_code':'MSR','eu':false,'europe':false},
    {'name':'Morocco','iso2_code':'MA','iso3_code':'MAR','eu':false,'europe':false},
    {'name':'Mozambique','iso2_code':'MZ','iso3_code':'MOZ','eu':false,'europe':false},
    {'name':'Myanmar','iso2_code':'MM','iso3_code':'MMR','eu':false,'europe':false},
    {'name':'Namibia','iso2_code':'NA','iso3_code':'NAM','eu':false,'europe':false},
    {'name':'Nauru','iso2_code':'NR','iso3_code':'NRU','eu':false,'europe':false},
    {'name':'Nepal','iso2_code':'NP','iso3_code':'NPL','eu':false,'europe':false},
    {'name':'Netherlands','iso2_code':'NL','iso3_code':'NLD','eu':true,'europe':true},
    {'name':'Netherlands Antilles','iso2_code':'AN','iso3_code':'ANT','eu':false,'europe':false},
    {'name':'New Caledonia','iso2_code':'NC','iso3_code':'NCL','eu':false,'europe':false},
    {'name':'New Zealand','iso2_code':'NZ','iso3_code':'NZL','eu':false,'europe':false},
    {'name':'Nicaragua','iso2_code':'NI','iso3_code':'NIC','eu':false,'europe':false},
    {'name':'Niger','iso2_code':'NE','iso3_code':'NER','eu':false,'europe':false},
    {'name':'Nigeria','iso2_code':'NG','iso3_code':'NGA','eu':false,'europe':false},
    {'name':'Niue','iso2_code':'NU','iso3_code':'NIU','eu':false,'europe':false},
    {'name':'Norfolk Island','iso2_code':'NF','iso3_code':'NFK','eu':false,'europe':false},
    {'name':'Northern Mariana Islands','iso2_code':'MP','iso3_code':'MNP','eu':false,'europe':false},
    {'name':'Norway','iso2_code':'NO','iso3_code':'NOR','eu':false,'europe':true},
    {'name':'Oman','iso2_code':'OM','iso3_code':'OMN','eu':false,'europe':false},
    {'name':'Pakistan','iso2_code':'PK','iso3_code':'PAK','eu':false,'europe':false},
    {'name':'Palau','iso2_code':'PW','iso3_code':'PLW','eu':false,'europe':false},
    {'name':'Palestinian Territory','iso2_code':'PS','iso3_code':'PSE','eu':false,'europe':false},
    {'name':'Panama','iso2_code':'PA','iso3_code':'PAN','eu':false,'europe':false},
    {'name':'Papua New Guinea','iso2_code':'PG','iso3_code':'PNG','eu':false,'europe':false},
    {'name':'Paraguay','iso2_code':'PY','iso3_code':'PRY','eu':false,'europe':false},
    {'name':'Peru','iso2_code':'PE','iso3_code':'PER','eu':false,'europe':false},
    {'name':'Philippines','iso2_code':'PH','iso3_code':'PHL','eu':false,'europe':false},
    {'name':'Pitcairn','iso2_code':'PN','iso3_code':'PCN','eu':false,'europe':false},
    {'name':'Poland','iso2_code':'PL','iso3_code':'POL','eu':true,'europe':true},
    {'name':'Portugal','iso2_code':'PT','iso3_code':'PRT','eu':true,'europe':true},
    {'name':'Puerto Rico','iso2_code':'PR','iso3_code':'PRI','eu':false,'europe':false},
    {'name':'Qatar','iso2_code':'QA','iso3_code':'QAT','eu':false,'europe':false},
    {'name':'Réunion','iso2_code':'RE','iso3_code':'REU','eu':false,'europe':false},
    {'name':'Romania','iso2_code':'RO','iso3_code':'ROU','eu':true,'europe':true},
    {'name':'Russian Federation','iso2_code':'RU','iso3_code':'RUS','eu':false,'europe':true},
    {'name':'Rwanda','iso2_code':'RW','iso3_code':'RWA','eu':false,'europe':false},
    {'name':'Saint-Barthélemy','iso2_code':'BL','iso3_code':'BLM','eu':false,'europe':false},
    {'name':'Saint Helena','iso2_code':'SH','iso3_code':'SHN','eu':false,'europe':false},
    {'name':'Saint Kitts and Nevis','iso2_code':'KN','iso3_code':'KNA','eu':false,'europe':false},
    {'name':'Saint Lucia','iso2_code':'LC','iso3_code':'LCA','eu':false,'europe':false},
    {'name':'Saint-Martin (French part)','iso2_code':'MF','iso3_code':'MAF','eu':false,'europe':false},
    {'name':'Saint Pierre and Miquelon','iso2_code':'PM','iso3_code':'SPM','eu':false,'europe':false},
    {'name':'Saint Vincent and Grenadines','iso2_code':'VC','iso3_code':'VCT','eu':false,'europe':false},
    {'name':'Samoa','iso2_code':'WS','iso3_code':'WSM','eu':false,'europe':false},
    {'name':'San Marino','iso2_code':'SM','iso3_code':'SMR','eu':false,'europe':true},
    {'name':'Sao Tome and Principe','iso2_code':'ST','iso3_code':'STP','eu':false,'europe':false},
    {'name':'Saudi Arabia','iso2_code':'SA','iso3_code':'SAU','eu':false,'europe':false},
    {'name':'Senegal','iso2_code':'SN','iso3_code':'SEN','eu':false,'europe':false},
    {'name':'Serbia','iso2_code':'RS','iso3_code':'SRB','eu':false,'europe':true},
    {'name':'Seychelles','iso2_code':'SC','iso3_code':'SYC','eu':false,'europe':false},
    {'name':'Sierra Leone','iso2_code':'SL','iso3_code':'SLE','eu':false,'europe':false},
    {'name':'Singapore','iso2_code':'SG','iso3_code':'SGP','eu':false,'europe':false},
    {'name':'Slovakia','iso2_code':'SK','iso3_code':'SVK','eu':true,'europe':true},
    {'name':'Slovenia','iso2_code':'SI','iso3_code':'SVN','eu':true,'europe':true},
    {'name':'Solomon Islands','iso2_code':'SB','iso3_code':'SLB','eu':false,'europe':false},
    {'name':'Somalia','iso2_code':'SO','iso3_code':'SOM','eu':false,'europe':false},
    {'name':'South Africa','iso2_code':'ZA','iso3_code':'ZAF','eu':false,'europe':false},
    {'name':'South Georgia','iso2_code':'GS','iso3_code':'SGS','eu':false,'europe':false},
    {'name':'South Sudan','iso2_code':'SS','iso3_code':'SSD','eu':false,'europe':false},
    {'name':'Spain','iso2_code':'ES','iso3_code':'ESP','eu':true,'europe':true},
    {'name':'Sri Lanka','iso2_code':'LK','iso3_code':'LKA','eu':false,'europe':false},
    {'name':'Sudan','iso2_code':'SD','iso3_code':'SDN','eu':false,'europe':false},
    {'name':'Suriname','iso2_code':'SR','iso3_code':'SUR','eu':false,'europe':false},
    {'name':'Svalbard and Jan Mayen Islands','iso2_code':'SJ','iso3_code':'SJM','eu':false,'europe':false},
    {'name':'Swaziland','iso2_code':'SZ','iso3_code':'SWZ','eu':false,'europe':false},
    {'name':'Sweden','iso2_code':'SE','iso3_code':'SWE','eu':true,'europe':true},
    {'name':'Switzerland','iso2_code':'CH','iso3_code':'CHE','eu':false,'europe':true},
    {'name':'Syrian Arab Republic (Syria)','iso2_code':'SY','iso3_code':'SYR','eu':false,'europe':false},
    {'name':'Taiwan, Republic of China','iso2_code':'TW','iso3_code':'TWN','eu':false,'europe':false},
    {'name':'Tajikistan','iso2_code':'TJ','iso3_code':'TJK','eu':false,'europe':false},
    {'name':'Tanzania, United Republic of','iso2_code':'TZ','iso3_code':'TZA','eu':false,'europe':false},
    {'name':'Thailand','iso2_code':'TH','iso3_code':'THA','eu':false,'europe':false},
    {'name':'Timor-Leste','iso2_code':'TL','iso3_code':'TLS','eu':false,'europe':false},
    {'name':'Togo','iso2_code':'TG','iso3_code':'TGO','eu':false,'europe':false},
    {'name':'Tokelau','iso2_code':'TK','iso3_code':'TKL','eu':false,'europe':false},
    {'name':'Tonga','iso2_code':'TO','iso3_code':'TON','eu':false,'europe':false},
    {'name':'Trinidad and Tobago','iso2_code':'TT','iso3_code':'TTO','eu':false,'europe':false},
    {'name':'Tunisia','iso2_code':'TN','iso3_code':'TUN','eu':false,'europe':false},
    {'name':'Turkey','iso2_code':'TR','iso3_code':'TUR','eu':false,'europe':true},
    {'name':'Turkmenistan','iso2_code':'TM','iso3_code':'TKM','eu':false,'europe':false},
    {'name':'Turks and Caicos Islands','iso2_code':'TC','iso3_code':'TCA','eu':false,'europe':false},
    {'name':'Tuvalu','iso2_code':'TV','iso3_code':'TUV','eu':false,'europe':false},
    {'name':'Uganda','iso2_code':'UG','iso3_code':'UGA','eu':false,'europe':false},
    {'name':'Ukraine','iso2_code':'UA','iso3_code':'UKR','eu':false,'europe':true},
    {'name':'United Arab Emirates','iso2_code':'AE','iso3_code':'ARE','eu':false,'europe':false},
    {'name':'United Kingdom','iso2_code':'GB','iso3_code':'GBR','eu':true,'europe':true},
    {'name':'United States of America','iso2_code':'US','iso3_code':'USA','eu':false,'europe':false},
    {'name':'US Minor Outlying Islands','iso2_code':'UM','iso3_code':'UMI','eu':false,'europe':false},
    {'name':'Uruguay','iso2_code':'UY','iso3_code':'URY','eu':false,'europe':false},
    {'name':'Uzbekistan','iso2_code':'UZ','iso3_code':'UZB','eu':false,'europe':false},
    {'name':'Vanuatu','iso2_code':'VU','iso3_code':'VUT','eu':false,'europe':false},
    {'name':'Venezuela','iso2_code':'VE','iso3_code':'VEN','eu':false,'europe':false},
    {'name':'Viet Nam','iso2_code':'VN','iso3_code':'VNM','eu':false,'europe':false},
    {'name':'Virgin Islands, US','iso2_code':'VI','iso3_code':'VIR','eu':false,'europe':false},
    {'name':'Wallis and Futuna Islands','iso2_code':'WF','iso3_code':'WLF','eu':false,'europe':false},
    {'name':'Western Sahara','iso2_code':'EH','iso3_code':'ESH','eu':false,'europe':false},
    {'name':'Yemen','iso2_code':'YE','iso3_code':'YEM','eu':false,'europe':false},
    {'name':'Zambia','iso2_code':'ZM','iso3_code':'ZMB','eu':false,'europe':false},
    {'name':'Zimbabwe','iso2_code':'ZW','iso3_code':'ZWE','eu':false,'europe':false}
];